<template>
  <div>
    <!-- <v-simple-table class="mt-5">
      <template v-slot:default>
        <thead>
          <tr>
            <th class="text-left">Senha</th>
            <th class="text-left">Tutor</th>
            <th class="text-left">Pet</th>
            <th class="text-left">Id Pet</th>
            <th class="text-left">Horario de Chegada</th>
            <th class="text-left">Urgencia</th>
            <th class="text-left">Observações</th>
            <th></th>
          </tr>
        </thead>
        <tbody>
          <tr
            v-for="paciente in pacientes"
            :key="paciente.nome"
            class="text-left"
          >
            <td>{{ paciente.Senha }}</td>
            <td>{{ paciente.NomeTutor || paciente.UsuarioSenha }}</td>
            <td>{{ paciente.NomePet }}</td>
            <td>{{ paciente.PetCodigo }}</td>
            <td>{{ paciente.DataCriacao.slice(11) }}</td>
            <td :class="urgenciaAtendimento(paciente.ClassificacaoAtendimento)">
              {{ paciente.ClassificacaoAtendimento }}
            </td>
            <td>
              <v-dialog v-model="dialogObservacoes" width="500">
                <template v-slot:activator="{ on, attrs }">
                  <v-btn small depressed color="error" v-bind="attrs" v-on="on"
                    >Observações
                  </v-btn>
                </template>
                <v-card>
                  <v-card-title class="text-h5 bg-danger text-white lighten-2">
                    Observações
                  </v-card-title>

                  <h5 class="text-justify pt-5 px-5">
                    {{ paciente.ObservacaoExtra }}
                  </h5>

                  <v-divider></v-divider>

                  <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn
                      color="primary"
                      text
                      @click="dialogObservacoes = false"
                    >
                      Ok
                    </v-btn>
                  </v-card-actions>
                </v-card>
              </v-dialog>
            </td>
            <td>
              <v-btn
                small
                depressed
                color="success"
                @click="chamarSenha(paciente.IdChamadaInterna)"
              >
                Chamar
              </v-btn>
            </td>
          </tr>
        </tbody>
      </template>
    </v-simple-table> -->
    <!-- <v-dialog max-width="500px" persistent fullscreen v-if="dialogAtender" v-model="dialogAtender">
      <modal-atender v-if="dialogAtender"
                     :paciente="pacienteEmAtendimento"
                     @close="dialogAtender = false"
      />
      <v-card> </v-card>
    </v-dialog> -->
    <v-data-table
      :headers="headers"
      :items="pacientes"
      :expanded.sync="expanded"
      item-key="IdChamadaInterna"
      show-expand
      class="elevation-1"
    >
      <template v-slot:item.tutor="{ item }">
        <span>{{ item.NomeTutor || item.UsuarioSenha }}</span>
      </template>
      <template v-slot:item.urgencia="{ item }">
        <span :class="urgenciaAtendimento(item.ClassificacaoAtendimento)">
          {{ item.ClassificacaoAtendimento }}
        </span>
      </template>
      <template v-slot:expanded-item="{ headers, item }">
        <td
          class="font-weight-bold text-left py-3 bg-white"
          :colspan="headers.length"
        >
          Observações:
          <b class="red--text font-weight-regular">{{
            item.ObservacaoExtra
          }}</b>
        </td>
      </template>
      <template v-slot:item.chamar="{ item }">
        <v-btn
          small
          depressed
          color="success"
          @click="chamarSenha(item.IdChamadaInterna)"
        >
          Chamar
        </v-btn>
      </template>
    </v-data-table>
  </div>
</template>

<script>
import { global } from "@/config/constants";
import axios from "axios";
// import ModalAtender from "@/components/fila_atendimento/modal_atender";

export default {
  data() {
    return {
      // pacienteEmAtendimento: {},
      // dialogAtender: false,
      tokenChamefacil: "",
      pacientes: [],
      listarFila: true,
      dialogObservacoes: false,
      headers: [
        {
          text: "Senha",
          align: "start",
          sortable: false,
          value: "Senha",
        },
        { text: "Tutor", value: "tutor" },
        { text: "Pet", value: "NomePet" },
        { text: "Id Pet", value: "PetCodigo" },
        { text: "Urgencia", value: "urgencia" },
        { text: "", value: "chamar" },
      ],
    };
  },
  // components: { ModalAtender },
  beforeMount() {
    this.tokenChamefacil = localStorage.getItem("tokenChamefacil");
    this.listarFilaChamefacil();
    var fila = setInterval(() => {
      if (this.listarFila == false) {
        clearInterval(fila);
      } else {
        this.listarFilaChamefacil();
      }
    }, 5000);
  },
  destroyed() {
    this.listarFila = false;
  },
  // watch: {
  //   pacientes() {
  //     setTimeout(() => {
  //       this.listarFilaChamefacil();
  //     }, 15000);
  //   },
  // },
  methods: {
    listarFilaChamefacil() {
      const headers = { token: this.tokenChamefacil };
      axios
        .get(
          global.api.baseUrlChamefacil +
            "/atendimento/listarsenhasinternaspendentesV2",
          { headers }
        )
        .then((resp) => {
          // console.log(resp.data.Senha);
          this.pacientes = resp.data.Senha;
        });
    },
    chamarSenha(idChamada) {
      this.$emit("chamarSenha", idChamada);
    },
    // atender(paciente) {
    //   if (!paciente.PetCodigo) {
    //     alert("O código do pet não está vinculado a esse atendimento. Verificar no chamefacil.")
    //   }
    //   this.pacienteEmAtendimento = paciente;
    //   this.dialogAtender = true;
    // },
    urgenciaAtendimento(urgencia) {
      return urgencia.toLowerCase() == "urgente"
        ? "red--text font-weight-bold"
        : "";
    },
  },
};
</script>

<style scoped></style>
