<template>
  <v-data-table
    :headers="dessertHeaders"
    :items="comandas"
    item-key="id"
    show-expand
    class="elevation-1"
    :loading="loading"
    loading-text="Carregando..."
  >
    <template v-slot:expanded-item="{ headers, item }">
      <td :colspan="headers.length">
        <table class="table table-striped">
          <thead>
            <tr>
              <th scope="col">Tipo</th>
              <th scope="col">Procedimento</th>
              <th scope="col">Convênio</th>
              <th scope="col">Data - Hora de Requisição</th>
              <th scope="col">Data - Hora de Execução</th>
              <th scope="col">Data - Hora do Pagamento</th>
              <th scope="col">Status</th>
              <th scope="col">Forma de pagamento</th>
              <th scope="col">Valor</th>
            </tr>
          </thead>
          <tbody>
            <tr
              v-for="itemComanda in item.itensComanda"
              :key="itemComanda.comanda_item_id"
            >
              <td>{{ itemComanda.tipo_procedimento_descricao }}</td>
              <td>{{ itemComanda.procedimento_descricao }}</td>
              <td>{{ itemComanda.tipo_convenio_descricao }}</td>
              <td>
                {{
                  `
                  ${itemComanda.data_requisicao
                    .split("-")
                    .reverse()
                    .join("/")} - 
                  ${itemComanda.hora_requisicao}
                `
                }}
              </td>
              <td>
                {{
                  itemComanda.data_execucao == "1900-01-01"
                    ? "-"
                    : `
                  ${itemComanda.data_execucao
                    .split("-")
                    .reverse()
                    .join("/")} - 
                  ${itemComanda.hora_execucao}
                `
                }}
              </td>
              <td>
                {{
                  itemComanda.data_pagamento == "1900-01-01"
                    ? "-"
                    : `
                  ${itemComanda.data_pagamento
                    .split("-")
                    .reverse()
                    .join("/")} - 
                  ${itemComanda.hora_pagamento}
                `
                }}
              </td>
              <td>{{ itemComanda.status_descricao }}</td>
              <td>{{ itemComanda.formaPagamento }}</td>
              <td>
                {{
                  Intl.NumberFormat("pt-BR", {
                    style: "currency",
                    currency: "BRL",
                  }).format(itemComanda.valor)
                }}
              </td>
            </tr>
          </tbody>
        </table>
      </td>
    </template>
    <template v-slot:item.actions="{ item }">
      <v-btn
        class="btn ml-5"
        @click="fecharComanda(item)"
        v-if="item.data_fechada == 'comanda aberta'"
        >Fechar comanda</v-btn
      >
    </template>
  </v-data-table>
</template>
<script>
import ComandaService from "@/services/comanda_service.js";
import Mixin from "@/mixins/vuex_mixin.js";

export default {
  mixins: [Mixin],
  emits: ["recarregarComandas"],
  props: {
    comandas: Array,
    loading: Boolean,
  },
  data() {
    return {
      dessertHeaders: [
        { text: "Id", align: "start", value: "id" },
        { text: "Tutor", align: "start", value: "nome_cliente" },
        { text: "Pet", value: "nome_pet" },
        { text: "Abertura", value: "data_criada" },
        { text: "Fechamento", value: "data_fechada" },
        { text: "Valor Total", value: "valor_total" },
        { text: "Valor Pago", value: "valor_pago" },
        { text: "", value: "actions" },
        { text: "", value: "data-table-expand" },
      ],
      comandaService: ComandaService.build(),
    };
  },
  methods: {
    async fecharComanda(comanda) {
      const success = await this.comandaService.fecharComanda(comanda.id ?? 0);
      if (success) {
        this.$_ACTIONS_showSnackbarMessage({
          message: "A comanda foi fechada com sucesso.",
          color: "sucess",
        });
        this.$emit("recarregarComandas");
      } else {
        this.$_ACTIONS_showSnackbarMessage({
          message: "Ocorreu um erro no fechamento da comanda.",
          color: "error",
        });
      }
    },
  },
};
</script>
