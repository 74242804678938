<template>
  <div>
    <v-card>
      <v-toolbar style="background-color: #3e682a; color: #fff">
        <v-btn icon dark @click="$emit('close')">
          <v-icon>mdi-close</v-icon>
        </v-btn>
        <v-toolbar-title>
          <span class="headline" v-if="edited || editedPerguntas"
            >Editar formulário</span
          >
          <span class="headline" v-else>Visualizar formulário</span>
        </v-toolbar-title>
        <v-spacer></v-spacer>
        <v-toolbar-items> </v-toolbar-items>
      </v-toolbar>
      <v-card-text>
        <v-container v-if="!loadingDetalhesFormulario">
          <v-form ref="form" v-model="valid" lazy-validation class="col-12">
            <div class="px-2 text-right">
              <v-btn text @click="edited = !edited">
                <v-icon v-if="edited"> mdi-close </v-icon>
                <v-icon v-else> mdi-pencil </v-icon>
              </v-btn>
            </div>
            <div class="d-flex flex-column col-12">
              <label for=""><strong>Título</strong></label>
              <v-text-field
                v-model="formEdit.titulo"
                placeholder="Ex: Consulta Básica"
                required
                class="col-6 m-auto"
                :disabled="!edited"
              ></v-text-field>
            </div>
            <div class="d-flex flex-column col-12">
              <label for=""><strong>Observação</strong></label>
              <v-text-field
                v-model="formEdit.descricao"
                placeholder="Ex: Formulário para uma primeira consulta"
                required
                :disabled="!edited"
                class="col-6 m-auto"
              ></v-text-field>
            </div>
            <!-- PERGUNTAS -->
            <div class="px-2 text-right">
              <v-btn text @click="editedPerguntas = !editedPerguntas">
                <v-icon v-if="editedPerguntas"> mdi-close </v-icon>
                <v-icon v-else> mdi-pencil </v-icon>
              </v-btn>
            </div>
            <div
              class="col-12 d-flex flex-column"
              v-for="(input, index) in formEdit.perguntas"
              :key="`pergunta${index}`"
            >
              <label for=""
                ><strong>Pergunta {{ index + 1 }}</strong></label
              >
              <div class="col-12 d-flex justify-center">
                <form-field-fill
                  :pergunta="input"
                  :disabled="!editedPerguntas"
                  v-if="input"
                  class="col-10"
                />
                <div class="col-2">
                  <v-btn
                    class="red"
                    color="error"
                    :disabled="!editedPerguntas"
                    @click="removerPergunta(index)"
                  >
                    <i class="fas fa-times"></i>
                  </v-btn>
                </div>
              </div>
            </div>
            <div>
              <formulario-build @addPergunta="addPergunta($event)" />
            </div>
            <div>
              <label for=""><strong>Procedimentos</strong></label>
              <v-select
                v-model="formEdit.procedimentos"
                :items="procedimentos"
                :disabled="!editedPerguntas"
                item-text="descricao"
                item-value="id"
                chips
                label="procedimentos"
                multiple
              ></v-select>
            </div>
            <v-btn
              color="success"
              :loading="loadingNovoFormulario"
              class="ml-3"
              :disabled="alterado()"
              @click="salvarFormularioSimples()"
              v-if="!editedPerguntas"
            >
              Salvar formulário
            </v-btn>
            <v-btn
              color="success"
              :loading="loadingNovoFormulario"
              class="ml-3"
              :disabled="alterado()"
              @click="salvarFormulario()"
              v-else
            >
              Salvar formulário
            </v-btn>
          </v-form>
        </v-container>
        <v-container v-else>
          <v-progress-circular
            indeterminate
            color="green"
          ></v-progress-circular>
        </v-container>
      </v-card-text>
    </v-card>
  </div>
</template>

<script>
import FormularioBuild from "@/components/formularios/formulario_campo_builder.vue";
import FormFieldFill from "@/components/formularios/formulario_campo_fill.vue";
import FormularioService from "@/services/formulario_service.js";
import ProcedimentoService from "@/services/procedimento_service.js";
import Mixin from "@/mixins/vuex_snackbar_mixin.js";

export default {
  mixins: [Mixin],
  props: {
    formulario: Object,
  },
  components: {
    FormularioBuild,
    FormFieldFill,
  },
  beforeMount() {
    this.detalhes_formulario(this.formulario.id);
    this.lista_procedimentos();
  },
  data: () => ({
    formEdit: {
      titulo: "",
      descricao: "",
      id_medico: 1,
      perguntas: [],
    },
    edited: false,
    editedPerguntas: false,
    procedimentos: [],
    procedimentos_form: [],
    loading_procedimentos: false,
    loadingNovoFormulario: false,
    loadingDetalhesFormulario: false,
    valid: true,
  }),

  methods: {
    alterado() {
      return this.editedPerguntas || this.edited ? false : true;
    },
    removerPergunta(index) {
      this.formEdit.perguntas.splice(index, 1);
    },
    addPergunta(e) {
      this.formEdit.perguntas.push(e);
    },
    salvarFormulario() {
      if (this.formEdit.titulo === "") {
        this.$_ACTIONS_showSnackbarMessage({
          message: "Campo título é obrigatório",
          color: "error",
        });
        return;
      }
      if (this.formEdit.procedimentos.length < 1) {
        this.$_ACTIONS_showSnackbarMessage({
          message: "Procedimento é um campo obrigatório",
          color: "error",
        });
        return;
      }
      if (this.formEdit.descricao === "") {
        this.$_ACTIONS_showSnackbarMessage({
          message: "Campo descrição é obrigatório",
          color: "error",
        });
        return;
      }
      if (this.formEdit.perguntas.length < 1) {
        this.$_ACTIONS_showSnackbarMessage({
          message: "O formulário deve conter no mínimo uma pergunta ",
          color: "error",
        });
        return;
      }
      const formulario = this.prepararFormulario(this.formEdit);
      const formularioService = new FormularioService();
      this.loadingNovoFormulario = true;
      if (this.editedPerguntas) {
        formularioService.updateCompleto(
          {
            onSucess: (status) => (body) => {
              if (status === 200) {
                this.$_ACTIONS_showSnackbarMessage({
                  message: body.message || "Formulário cadastrado com sucesso",
                  color: "sucess",
                });
                this.$emit("close", true);
              } else {
                this.$_ACTIONS_showSnackbarMessage({
                  message: this.$global.messages.internalServerError,
                  color: "error",
                });
              }
            },
            onError: (error) => {
              this.$emit("submit", { error });
            },
            onEnd: () => {
              this.loadingNovoFormulario = false;
            },
          },
          formulario,
          this.formulario.id
        );
      } else {
        formularioService.updateSimples(
          {
            onSucess: (status) => (body) => {
              if (status === 200) {
                this.$_ACTIONS_showSnackbarMessage({
                  message: body.message || "Formulário cadastrado com sucesso",
                  color: "sucess",
                });
                this.$emit("close", true);
              } else {
                this.$_ACTIONS_showSnackbarMessage({
                  message: this.$global.messages.internalServerError,
                  color: "error",
                });
              }
            },
            onError: (error) => {
              this.$emit("submit", { error });
            },
            onEnd: () => {
              this.loadingNovoFormulario = false;
            },
          },
          formulario,
          this.formulario.id
        );
      }
    },
    salvarFormularioSimples() {
      if (this.formEdit.titulo === "") {
        this.$_ACTIONS_showSnackbarMessage({
          message: "Campo título é obrigatório",
          color: "error",
        });
        return;
      }
      if (this.formEdit.descricao === "") {
        this.$_ACTIONS_showSnackbarMessage({
          message: "Campo descrição é obrigatório",
          color: "error",
        });
        return;
      }
      const formulario = {
        titulo: this.formEdit.titulo,
        descricao: this.formEdit.descricao,
        id_medico: 1,
      };
      const formularioService = new FormularioService();
      this.loadingNovoFormulario = true;
      formularioService.shortUpdate(
        {
          onSucess: (status) => (body) => {
            if (status === 200) {
              this.$_ACTIONS_showSnackbarMessage({
                message: body.message || "Formulário cadastrado com sucesso",
                color: "sucess",
              });
              this.$emit("close", true);
            } else {
              this.$_ACTIONS_showSnackbarMessage({
                message: this.$global.messages.internalServerError,
                color: "error",
              });
            }
          },
          onError: (error) => {
            this.$emit("submit", { error });
          },
          onEnd: () => {
            this.loadingNovoFormulario = false;
          },
        },
        formulario,
        this.formulario.id
      );
    },
    prepararFormulario(form) {
      let perguntas = [];
      form.perguntas.map((pergunta, i) => {
        let opcoes_toarray = [];
        if (pergunta.opcoes) {
          for (let index = 0; index < pergunta.opcoes.length; index++) {
            opcoes_toarray.push(pergunta.opcoes[index].descricao);
          }
          perguntas[i] = {
            texto_pergunta: pergunta.texto_pergunta,
            id_tipo_pergunta: pergunta.id_tipo_pergunta,
            obrigatorio: pergunta.obrigatorio,
            opcoes: opcoes_toarray,
            ordem: pergunta.ordem,
            ativo: pergunta.ativo,
          };
        } else {
          delete form.perguntas[i].opcoes;
          perguntas[i] = form.perguntas[i];
        }
      });
      const formulario = {
        titulo: form.titulo,
        descricao: form.descricao,
        id_medico: 1,
        procedimentos: this.formEdit.procedimentos,
        perguntas: perguntas,
      };
      return formulario;
    },
    lista_procedimentos() {
      this.loading_procedimentos = true;
      const procedimentoService = new ProcedimentoService();
      procedimentoService.getList({
        onSucess: (status) => (body) => {
          if (status === 200) {
            this.procedimentos = body.data.procedimentos;
          } else {
            this.procedimentos = [];
          }
        },
        onError: (error) => {
          console.error(error);
          this.procedimentos = [];
        },
        onEnd: () => {
          this.loading_procedimentos = false;
        },
      });
    },
    detalhes_formulario(id) {
      this.loadingDetalhesFormulario = true;
      const formularioService = new FormularioService();
      formularioService.get(
        {
          onSucess: (status) => (body) => {
            if (status === 200) {
              this.formEdit.titulo = body.data.titulo;
              this.formEdit.descricao = body.data.descricao;
              this.formEdit.id_medico = body.data.id_medico;
              this.formEdit.perguntas = body.data.perguntas.sort((a, b) => {
                if (a.ordem < b.ordem) return -1;
                if (a.ordem > b.ordem) return 1;
                return 0;
              });
              this.formEdit.procedimentos = this.procedimentosItens(
                body.data.procedimentos
              );
            } else {
              this.$_ACTIONS_showSnackbarMessage({
                message: this.$global.messages.internalServerError,
                color: "error",
              });
            }
          },
          onError: (error) => {
            this.$emit("close", { error });
          },
          onEnd: () => {
            this.loadingDetalhesFormulario = false;
          },
        },
        id
      );
    },
    procedimentosItens(procedimento) {
      let i = 0;
      let resp = [];
      while (i < procedimento.length) {
        resp[i] = +procedimento[i];
        i++;
      }
      return resp;
    },
  },
};
</script>

<style scoped>
.form-group label {
  text-align: left !important;
  display: block;
}

.form-group .label-genero {
  margin-right: 1rem;
}

.v-progress-circular {
  margin: 1rem;
}
</style>
