<template>
    <div>
        <div class="p-lg-3 mb-4">
            <div class="row justify-content-between">
                <h3 class="mt-0 mb-2 font-weight-bold text-left">
                    Dados Cadastrais
                </h3>
            </div>
        </div>

        <div class="row">
            <div class="col-md-4 d-flex align-items-center">
               <upload-foto-perfil/>
            </div>

            <div class="col-md-8">
                <form-primeira-etapa @reloadData="reloadData"/>
            </div>

            <hr style="display: block; width: 100%; color: #000; margin: 10px 0; border-top: 3px solid #ededed;">
            
            <div class="col-md-12">
                <form-segunda-etapa @reloadData="reloadData"/>      
            </div>

            <hr style="display: block; width: 100%; color: #000; margin: 10px 0; border-top: 3px solid #ededed;">
            
            <div class="col-md-12">
                <form-terceira-etapa @reloadData="reloadData"/>
            </div>

            <div class="col-md-12">
                <form-alterar-senha/>
            </div>
        </div>

    </div>
</template>

<script>
import FormPrimeiraEtapa from '@/components/dados_cadastrais/form_primeira_etapa.vue'
import FormSegundaEtapa from '@/components/dados_cadastrais/form_segunda_etapa.vue'
import FormTerceiraEtapa from '@/components/dados_cadastrais/form_terceira_etapa.vue'
import FormAlterarSenha from '@/components/dados_cadastrais/form_alterar_senha.vue'
import UploadFotoPerfil from '../components/dados_cadastrais/upload_foto_perfil.vue'
import UsuarioService from '@/services/usuario_service.js'
import UsuarioMixin from '@/mixins/vuex_usuario_mixin.js'
import DadosCadastraisMixin from "@/mixins/dados_cadastrais_mixin.js";

export default {
    data: () => ({
        usuario: {
            nome: '',
            email: '',
            telefone_1: '',
            telefone_2: '',
            cpf: '',
            rg: '',
            data_nascimento: '',
            genero: '',
            cep: '',
            logradouro: '',
            bairro: '',
            numero: '',
            complemento: '',
            referencias: '',
            cidade: '',
            estado: '',
            foto: '',
        }
    }),
    mixins: [UsuarioMixin, DadosCadastraisMixin],
    components: {
        UploadFotoPerfil, 
        FormPrimeiraEtapa,
        FormSegundaEtapa,
        FormTerceiraEtapa,
        FormAlterarSenha,
    },
    beforeMount() {
        this.getDadosCadastrais();
    },
    methods: {
        reloadData() {
            this.getDadosCadastrais()
        },
        getDadosCadastrais () {
            const usuarioService = new UsuarioService();
            this.loading = true;
            this.$_GETTERS_usuario.id
            const idUsuario = this.$_GETTERS_usuario.id;
            usuarioService.getUsuarioData(
            {
                onSucess: status => body => {
                    if (status === 200) {
                        console.log(body)
                    } else {
                        console.log(status)
                        console.error(body)
                    }
                },
                onError: error => {
                    console.log(error);
                },
                onEnd: () => {
                    this.loading = false;
                }
            }, idUsuario);
        }
    }
}
</script>

<style scoped>
.form-group label {
    text-align: left !important;
    display: block;
}

.form-group .label-genero {
    margin-right: 1rem;
}

</style>