<template>
  <div>
    <v-card>
      <v-toolbar style="background-color: #3e682a; color: #fff">
        <v-btn icon dark @click="$emit('close')">
          <v-icon>mdi-close</v-icon>
        </v-btn>
        <v-toolbar-title>
          <span class="headline">Declaração</span>
        </v-toolbar-title>
        <v-spacer></v-spacer>
      </v-toolbar>
      <v-card-text>
        <v-container>
          <div>
            <v-select
              v-model="tipo"
              :items="tipos_declaracao"
              label="Tipo de declaração"
              class="mb-2"
            >
            </v-select>
            <v-select
              v-model="convenioSelecionado"
              :items="convenios"
              label="Convênio"
              class="mb-2"
            >
            </v-select>
          </div>
        </v-container>
        <div class="row my-2">
          <div class="col-md-12 text-right d-flex justify-lg-space-between">
            <button
              class="btn btn-cancelar btn-padding font-weight-bold text-gray"
              @click="$emit('close')"
            >
              Cancelar
            </button>
            <button
              class="btn btn-app-primary btn-padding font-weight-bold"
              @click="gerarPdf()"
            >
              <span> Gerar PDF </span>
            </button>
          </div>
        </div>
      </v-card-text>
    </v-card>
  </div>
</template>

<script>
export default {
  data: () => ({
    tipo: "",
    tipos_declaracao: ["Atestado", "Declaração de horas"],
    convenios: ["Público", "Particular"],
    convenioSelecionado: "",
  }),
  mounted() {},
  methods: {
    gerarPdf() {
      this.$emit("gerarPDF", {
        doc: "atestado",
        tipo: this.tipo.toLowerCase(),
        convenio: this.convenioSelecionado
          .normalize("NFD")
          .replace(/[\u0300-\u036f]/g, "")
          .toLowerCase(),
      });
    },
  },
};
</script>
