var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-data-table',{staticClass:"elevation-1",attrs:{"headers":_vm.headers,"items":_vm.pacientes,"expanded":_vm.expanded,"item-key":"IdChamadaInterna","show-expand":""},on:{"update:expanded":function($event){_vm.expanded=$event}},scopedSlots:_vm._u([{key:"item.DataCriacao",fn:function(ref){
var item = ref.item;
return [_c('span',[_vm._v(_vm._s(item.DataCriacao.split(" ")[0] .split("-") .reverse() .join("/")))])]}},{key:"item.HoraCriacao",fn:function(ref){
var item = ref.item;
return [_c('span',[_vm._v(_vm._s(item.DataCriacao.split(" ")[1]))])]}},{key:"item.tutor",fn:function(ref){
var item = ref.item;
return [_c('span',[_vm._v(_vm._s(item.NomeTutor || item.UsuarioSenha))])]}},{key:"item.urgencia",fn:function(ref){
var item = ref.item;
return [_c('span',{class:_vm.urgenciaAtendimento(item.ClassificacaoAtendimento)},[_vm._v(" "+_vm._s(item.ClassificacaoAtendimento)+" ")])]}},{key:"expanded-item",fn:function(ref){
var headers = ref.headers;
var item = ref.item;
return [_c('td',{staticClass:"font-weight-bold text-left py-3 bg-white",attrs:{"colspan":headers.length}},[_vm._v(" Observações: "),_c('b',{staticClass:"red--text font-weight-regular"},[_vm._v(_vm._s(item.ObservacaoExtra))])])]}},{key:"item.chamar",fn:function(ref){
var item = ref.item;
return [_c('v-btn',{attrs:{"small":"","depressed":"","color":"success"},on:{"click":function($event){return _vm.chamarSenha(item.IdChamadaInterna)}}},[_vm._v(" Chamar ")])]}}])})}
var staticRenderFns = []

export { render, staticRenderFns }