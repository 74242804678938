<template>
  <v-card>
    <v-toolbar style="background-color: #3e682a; color: #fff">
      <v-btn icon dark @click="$emit('close')">
        <v-icon>mdi-close</v-icon>
      </v-btn>
      <v-toolbar-title>
        <span class="headline">Requisitar Internaçao</span>
      </v-toolbar-title>
      <v-spacer></v-spacer>
      <v-toolbar-items> </v-toolbar-items>
    </v-toolbar>
    <div class="p-5">
      <v-row class="mb-3">
        <v-data-table
          v-on:update:sort-by="options.ordenacao = true"
          :footer-props="{ 'items-per-page-options': [5, 10, 15, 20] }"
          :headers="headersProcedimentos"
          :items="procedimentos"
          :search="search"
          class="elevation-1 col-6"
          :loading="loadingProcedimentos"
          :loading-text="'Carregando...'"
        >
          <template v-slot:top>
            <v-toolbar flat class="mb-5">
              <v-text-field
                v-model="search"
                append-icon="mdi-magnify"
                label="Pesquisar"
                single-line
                hide-details
              />
            </v-toolbar>
          </template>
          <template v-slot:item.actions="{ item }">
            <v-btn
              color="#3E682A"
              dark
              elevation="0"
              small
              class="mb-2"
              @click="listarConvenio(item)"
            >
              Carregar Convênios
            </v-btn>
          </template>
          <template v-slot:no-data>
            <p class="text-center">Nenhum registro encontrado</p>
          </template>
        </v-data-table>

        <v-data-table
          v-on:update:sort-by="options.ordenacao = true"
          :footer-props="{ 'items-per-page-options': [5, 10, 15, 20] }"
          :headers="headersConvenios"
          :items="convenios"
          class="elevation-1 col-6"
          :loading="loadingConvenios"
          :loading-text="'Carregando...'"
        >
          <template v-slot:item.actions="{ item }">
            <v-btn
              color="#3E682A"
              dark
              elevation="0"
              small
              class="mb-2"
              @click="cadastrarRequisicao(item)"
            >
              Requisitar
            </v-btn>
          </template>
          <template v-slot:no-data>
            <p class="text-center">Nenhum registro encontrado</p>
          </template>
        </v-data-table>
      </v-row>
      <v-row class="my-3">
        <v-data-table
          v-on:update:sort-by="options.ordenacao = true"
          :footer-props="{ 'items-per-page-options': [5, 10, 15, 20] }"
          :headers="headersRequisicoes"
          :items="requisicoes"
          class="elevation-1 col-6"
          :loading="loadingRequisicoes"
          :loading-text="'Carregando...'"
        >
          <template v-slot:item.actions="{ item }">
            <v-btn
              color="#3E682A"
              dark
              elevation="0"
              small
              class="mb-2"
              @click="excluirRequisicao(item)"
            >
              Excluir
            </v-btn>
          </template>
          <template v-slot:no-data>
            <p class="text-center">Nenhum registro encontrado</p>
          </template>
        </v-data-table>
      </v-row>
    </div>
  </v-card>
</template>

<script>
import Mixin from "@/mixins/vuex_snackbar_mixin.js";
import ConvenioService from "@/services/convenio_service.js";
import RequisicaoExameService from "@/services/requisicao_exame_service.js";
import VuexUsuarioMixin from "@/mixins/vuex_usuario_mixin.js";
import DadosCadastraisMixin from "@/mixins/dados_cadastrais_mixin.js";

export default {
  mixins: [Mixin, VuexUsuarioMixin, DadosCadastraisMixin],
  data: () => ({
    procedimentos: [],
    convenios: [],
    loadingProcedimentos: false,
    loadingConvenios: false,
    headersProcedimentos: [
      {
        text: "Procedimento",
        align: "start",
        sortable: true,
        value: "procedimentoDescricao",
      },
      {
        text: "Tipo Procedimento",
        align: "start",
        sortable: true,
        value: "tipoProcedimento",
      },
      { text: "Ações", align: "end", value: "actions", sortable: false },
    ],
    headersConvenios: [
      {
        text: "Convênio",
        align: "start",
        sortable: true,
        value: "convenioDescricao",
      },
      {
        text: "Tipo Convênio",
        align: "start",
        sortable: true,
        value: "tipoConvenio",
      },
      {
        text: "Valor",
        align: "start",
        sortable: true,
        value: "valor",
      },
      { text: "Ações", align: "center", value: "actions", sortable: false },
    ],
    requisicoes: [],
    loadingRequisicoes: false,
    headersRequisicoes: [
      {
        text: "Procedimento",
        align: "start",
        sortable: true,
        value: "procedimentoDescricao",
      },
      {
        text: "Convênio",
        align: "start",
        sortable: true,
        value: "convenioDescricao",
      },
      {
        text: "Tipo Convênio",
        align: "start",
        sortable: true,
        value: "tipoConvenio",
      },
      { text: "Ações", align: "center", value: "actions", sortable: false },
    ],
    search: "",
    convenioService: ConvenioService.build(),
    requisicaoExameService: RequisicaoExameService.build(),
    unidadeId: 0,
  }),
  props: {
    petId: {
      required: true,
    },
    consultaId: {
      required: true,
    },
  },
  watch: {
    search() {
      if (this.search.length >= 3) this.setProcedimentos();
    },
  },
  async mounted() {
    this.unidadeId = await JSON.parse(sessionStorage.getItem("vuex")).unidade
      .id;
    await this.setRequisicoes(this.unidadeId);
    await this.setProcedimentos();
  },
  computed: {},
  methods: {
    async setProcedimentos() {
      this.loadingProcedimentos = true;
      await this.convenioService
        .getProcedimentosInternacao(this.unidadeId, this.search)
        .then(async (response) => {
          const { data } = await response.json();
          console.log(data.procedimentos);
          this.procedimentos = data.procedimentos
            .filter((item) => item.id_tipo_procedimento === 6)
            .map((item) => {
              return {
                procedimentoId: item.id,
                procedimentoDescricao: item.descricao,
                tipoProcedimento: item.tipo_procedimento,
              };
            });
        })
        .catch((error) => {
          console.error(error);
        })
        .finally(() => {
          this.loadingProcedimentos = false;
        });
    },
    async cadastrarRequisicao(convenio) {
      await this.requisicaoExameService
        .cadastrarRequisicaoExame({
          id_medico: this.$_GETTERS_usuario.dados.medicoId
            ? this.$_GETTERS_usuario.dados.medicoId
            : null,
          id_convenio_procedimento: convenio.convenioProcedimentoId,
          id_consulta: this.consultaId,
          id_pet: this.petId,
        })
        .then(async (response) => {
          if (response.status !== 200) {
            this.$_SET_SNACKBAR({
              text: "Erro ao requisitar exame",
              color: "error",
            });
            await this.setRequisicoes(this.unidadeId);
          }
          this.$_ACTIONS_showSnackbarMessage({
            message: "Requisição cadastrada com sucesso!",
            color: "sucess",
          });
          await this.setRequisicoes(this.unidadeId);
        })
        .catch((error) => {
          console.error(error);
        });
    },
    async setRequisicoes(unidadeId) {
      await this.requisicaoExameService
        .getRequisicoesInternacoesAbertas(this.petId, unidadeId)
        .then(async (response) => {
          if (response.status == 200) {
            const { data } = await response.json();
            if (!data.length) return;
            this.requisicoes = data.map((item) => {
              return {
                requisicaoId: item.requisicao_procedimento_id,
                procedimentoDescricao: item.procedimento_descricao,
                convenioDescricao: item.convenio_descricao,
                tipoConvenio: item.tipo_convenio_descricao,
              };
            });
          } else {
            console.log(response);
          }
        });
    },
    async listarConvenio(procedimento) {
      this.loadingConvenios = true;
      await this.convenioService
        .getConvenioPorUnidadeProcedimento(
          this.unidadeId,
          procedimento.procedimentoId
        )
        .then(async (response) => {
          const { data } = await response.json();
          this.convenios = data.map((item) => {
            return {
              convenioId: item.convenio_id,
              convenioDescricao: item.convenio_descricao,
              tipoConvenio: item.tipo_convenio_descricao,
              convenioProcedimentoId: item.convenio_procedimento_id,
              valor: Intl.NumberFormat("pt-BR", {
                style: "currency",
                currency: "BRL",
              }).format(item.valor),
            };
          });
        })
        .catch((error) => {
          console.error(error);
        })
        .finally(() => {
          this.loadingConvenios = false;
        });
    },
    async excluirRequisicao(requisicao) {
      this.loadingRequisicoes = true;
      await this.requisicaoExameService
        .excluirRequisicao(parseInt(requisicao.requisicaoId))
        .then(async (response) => {
          if (response.status !== 200) {
            this.$_ACTIONS_showSnackbarMessage({
              message: "Erro ao excluir requisição",
              color: "error",
            });
            await this.setRequisicoes(this.unidadeId);
            return;
          }
          this.$_ACTIONS_showSnackbarMessage({
            message: "Requisição excluída com sucesso!",
            color: "sucess",
          });
          await this.setRequisicoes(this.unidadeId);
        })
        .catch((error) => {
          console.error(error);
        })
        .finally(() => {
          this.loadingRequisicoes = false;
        });
    },
  },
};
</script>
