<template>
  <div>
    <ul class="d-flex list-unstyled menu-left mb-0 pl-0">
      <li class="float-left">
        <a href="/principal" class="logo">
          <span class="logo-lg">
            <img
              src="@/assets/images/logo-horizontal_2.png"
              alt=""
              height="24"
            />
          </span>
          <span class="logo-sm">
            <img src="assets/images/logo-sm.png" alt="" height="24" />
          </span>
        </a>
      </li>
    </ul>
    <div class="slimscroll-menu w-100">
      <div class="left-side-menu shadow-none">
        <div
          id="sidebar-menu"
          style="height: 100%; overflow-y: auto"
          v-if="!isClient"
        >
          <v-list-group color="#fff">
            <template v-slot:prependIcon>
              <v-icon style="color: #fff"> mdi-heart-plus</v-icon>
            </template>

            <template v-slot:appendIcon>
              <v-icon style="color: #fff"> mdi-menu-down</v-icon>
            </template>
            <template v-slot:activator>
              <v-list-item-title style="color: #fff">
                Assistência
              </v-list-item-title>
            </template>
            <v-list color="#3e682a" dense>
              <v-list-item
                color="#3e682a"
                v-for="(option, index) in getAssistencias(options)"
                :key="index"
                @click="redirectTo(option.path)"
              >
                <v-list-item-icon>
                  <v-icon small color="#fff" v-text="option.icon"></v-icon>
                </v-list-item-icon>

                <v-list-item-title
                  class="text-start"
                  style="color: #fff"
                  v-text="option.label"
                ></v-list-item-title>
              </v-list-item>
            </v-list>
          </v-list-group>

          <v-list-group color="#fff" v-if="perfilId == 1">
            <template v-slot:prependIcon>
              <v-icon style="color: #fff"> mdi-note-plus</v-icon>
            </template>
            <template v-slot:appendIcon>
              <v-icon style="color: #fff"> mdi-menu-down</v-icon>
            </template>
            <template v-slot:activator>
              <v-list-item-title style="color: #fff">
                Cadastral
              </v-list-item-title>
            </template>
            <v-list color="#3e682a" dense>
              <v-list-item
                color="#3e682a"
                v-for="(option, index) in getCadastrais(options)"
                :key="index"
                @click="redirectTo(option.path)"
              >
                <v-list-item-icon>
                  <v-icon small color="#fff" v-text="option.icon"></v-icon>
                </v-list-item-icon>

                <v-list-item-title
                  class="text-start"
                  style="color: #fff"
                  v-text="option.label"
                ></v-list-item-title>
              </v-list-item>
            </v-list>
          </v-list-group>

          <v-list-group color="#fff">
            <template v-slot:prependIcon>
              <v-icon style="color: #fff"> mdi-chart-areaspline </v-icon>
            </template>
            <template v-slot:appendIcon>
              <v-icon style="color: #fff"> mdi-menu-down</v-icon>
            </template>
            <template v-slot:activator>
              <v-list-item-title style="color: #fff">
                Relatórios
              </v-list-item-title>
            </template>
            <v-list color="#3e682a" dense>
              <v-list-item
                color="#3e682a"
                v-for="(option, index) in getRelatorios(options)"
                :key="index"
                @click="redirectTo(option.path)"
              >
                <v-list-item-icon>
                  <v-icon small color="#fff" v-text="option.icon"></v-icon>
                </v-list-item-icon>

                <v-list-item-title
                  class="text-start"
                  style="color: #fff"
                  v-text="option.label"
                ></v-list-item-title>
              </v-list-item>
            </v-list>
          </v-list-group>

          <v-list-group color="#fff">
            <template v-slot:prependIcon>
              <v-icon style="color: #fff"> mdi-account-details </v-icon>
            </template>
            <template v-slot:appendIcon>
              <v-icon style="color: #fff"> mdi-menu-down</v-icon>
            </template>
            <template v-slot:activator>
              <v-list-item-title style="color: #fff"> Filas </v-list-item-title>
            </template>
            <v-list color="#3e682a" dense>
              <v-list-item
                color="#3e682a"
                v-for="(option, index) in getFilas(options)"
                :key="index"
                @click="redirectTo(option.path)"
              >
                <v-list-item-icon>
                  <v-icon small color="#fff" v-text="option.icon"></v-icon>
                </v-list-item-icon>

                <v-list-item-title
                  class="text-start"
                  style="color: #fff"
                  v-text="option.label"
                ></v-list-item-title>
              </v-list-item>
            </v-list>
          </v-list-group>

          <v-list-group color="#fff" v-if="telemedicinaVisivel">
            <template v-slot:prependIcon>
              <v-icon style="color: #fff"> mdi-account-details </v-icon>
            </template>
            <template v-slot:appendIcon>
              <v-icon style="color: #fff"> mdi-menu-down</v-icon>
            </template>
            <template v-slot:activator>
              <v-list-item-title style="color: #fff; font-size: 0.9rem;">
                Telemedicina
              </v-list-item-title>
            </template>
            <v-list color="#3e682a" dense>
              <v-list-item
                color="#3e682a"
                v-for="(option, index) in getTelemedicina(options)"
                :key="index"
                @click="redirectTo(option.path)"
              >
                <v-list-item-icon>
                  <v-icon small color="#fff" v-text="option.icon"></v-icon>
                </v-list-item-icon>

                <v-list-item-title
                  class="text-start"
                  style="color: #fff"
                  v-text="option.label"
                ></v-list-item-title>
              </v-list-item>
            </v-list>
          </v-list-group>
        </div>
        <div id="sidebar-menu" style="height: 100%; overflow-y: auto" v-else>
          <v-column>
            <v-btn
              class="menu-item"
              text
              color="#fff"
              @click="redirectTo('dadoscadastrais')"
            >
              Meus Dados
            </v-btn>
            <v-btn
              class="menu-item"
              text
              color="#fff"
              @click="redirectTo('meus-pets')"
            >
              Meus Pets
            </v-btn>
            <v-btn
              class="menu-item"
              text
              color="#fff"
              @click="redirectTo('agendar_consulta')"
            >
              Agendar consulta
            </v-btn>
            <v-btn
              class="menu-item"
              text
              color="#fff"
              @click="redirectTo('minhas-consultas')"
            >
              Minhas Consultas
            </v-btn>
            <v-btn
              class="menu-item"
              text
              color="#fff"
              @click="redirectTo('agendar-teleconsulta')"
            >
              Agendar TeleConsulta
            </v-btn>
          </v-column>
        </div>
        <div class="clearfix"></div>
      </div>
    </div>
  </div>
</template>

<script>
import VuexUsuarioMixin from "@/mixins/vuex_mixin.js";
import AuthService from "../../services/auth_service";

export default {
  mixins: [VuexUsuarioMixin],
  // props: { isClient: Boolean },
  data: () => ({
    authService: AuthService.build(),
    isClient: false,
    cadastrais: [
      "cadastro-paciente",
      "unidades",
      "centro_custo",
      "forma_pagamento",
      "setor",
      "perfis",
      "medicos",
      "administrativos",
      "assistentes",
      "formularios",
      "procedimentos",
      // "classificacao-risco",
      "procedimentosEspecialidade",
      "procedimentos-valor",
      "especialidade",
      "agendamento-especialidades",
      "pre-agendamento",
      "tipos-procedimentos",
      "tipos-estorno",
      "exames",
      "medicamentos",
      "horarios",
      "especies",
      "racas",
      "convenios",
      "atendentes",
    ],
    assistencias: [
      "home",
      "fila_atendimento",
      "requisicoes-consultas",
      // "tutores",
      "tutores",
      "pets",
      "dashboard",
      "recepcao",
      "agendar_consulta",
      "agendamento_especialidade_recepcionista",
      "minhas_consultas",
      "prontuarios",
      "agendamentos",
      "meu-prontuario",
      "importacao",
      "filasatendimentomedico",
      "minhas_agendas",
      "requisicoes-exames",
    ],
    relatorios: [
      "relatorio-comandas",
      "relatorio-itens-excluidos",
      "relatorio-procedimentos",
      "recebimentos",
    ],
    filas: ["acompanhar-filas"],
    telemedicina: ["telemedicina"],
    perfilId: null,
    telemedicinaVisivel: false,
  }),
  computed: {
    options() {
      const routes = this.$router.options.routes;
      const perfilId = ~~JSON.parse(sessionStorage.vuex).perfil.id;
      return routes
        .filter(({ name, menu, endpoints, perfis }) => {
          if (~~JSON.parse(sessionStorage.vuex).perfil.id === 4) {
            // assistente
            return ["agendar_consulta", "minhas_consultas", "pets"].includes(
              name
            );
          }

          if (
            ~~JSON.parse(sessionStorage.vuex).perfil.id !== 2 &&
            name === "filasatendimentomedico"
          ) {
            // medico
            return false;
          }

          if (
            ~~JSON.parse(sessionStorage.vuex).perfil.id !== 2 &&
            name === "minhas_agendas"
          ) {
            // medico
            return false;
          }

          if (
            ~~JSON.parse(sessionStorage.vuex).perfil.id !== 3 &&
            name === "fila_atendimento"
          ) {
            // assistente
            return false;
          }

          if (
            ~~JSON.parse(sessionStorage.vuex).perfil.id === 2 &&
            name === "agendamentos"
          ) {
            // assistente
            return false;
          }

          if (
            ~~JSON.parse(sessionStorage.vuex).perfil.id === 4 &&
            name === "agendamentos"
          ) {
            // assistente
            return false;
          }

          if (
            ~~JSON.parse(sessionStorage.vuex).perfil.id === 4 &&
            name === "tutores"
          ) {
            // assistente
            return false;
          }

          if (
            ~~JSON.parse(sessionStorage.vuex).perfil.id !== 4 &&
            name === "minhas_consultas"
          ) {
            // assistente
            return false;
          }

          if ((perfilId !== 56) & (perfilId !== 1) & (name === "recepcao")) {
            // assistente
            return false;
          }

          if (
            ~~JSON.parse(sessionStorage.vuex).perfil.id === 3 &&
            name === "dashboard"
          ) {
            // assistente
            return false;
          }

          const perfilMatch = perfis
            ? perfis.includes(JSON.parse(sessionStorage.vuex).perfil.id)
            : true;
          const usuarioPossuiPeloMenosUmEndpoint = endpoints.some((endpoint) =>
            this.authService.temPermissao(endpoint)
          );
          return (
            perfilMatch &&
            menu &&
            (usuarioPossuiPeloMenosUmEndpoint || !endpoints.length)
          ); // se a lista de endpoints for vazia, libera
        })
        .map(({ label, name, path, icon }) => ({
          label: (() => {
            if (
              name === "pets" &&
              JSON.parse(sessionStorage.vuex).perfil.id === 4
            ) {
              // se a rota for dos pets e o usuário logado for cliente
              return "Meus pets";
            }

            if (
              name === "agendamentos" &&
              ~~JSON.parse(sessionStorage.vuex).perfil.id === 2
            ) {
              return "Minhas agendas";
            }
            return label;
          })(),
          name,
          path,
          icon,
        }));
    },
  },
  methods: {
    isCadastral(option) {
      return this.cadastrais.includes(option.name);
    },
    getCadastrais(options) {
      return options.filter((option) => this.isCadastral(option));
    },
    isAssistencia(option) {
      return this.assistencias.includes(option.name);
    },
    getAssistencias(options) {
      return options.filter((option) => this.isAssistencia(option));
    },
    isRelatorio(option) {
      return this.relatorios.includes(option.name);
    },
    getRelatorios(options) {
      return options.filter((option) => this.isRelatorio(option));
    },
    isFilas(option) {
      return this.filas.includes(option.name);
    },
    getFilas(options) {
      return options.filter((option) => this.isFilas(option));
    },
    isTelemedicina(option) {
      return this.telemedicina.includes(option.name);
      // return option.name;
    },
    getTelemedicina(options) {
      return options.filter((option) => this.isTelemedicina(option));
    },
    redirectTo(path) {
      this.$router.push(path);
    },
  },
  mounted() {
    this.perfilId = this.$_GETTER_perfil.id;
    this.isClient = this.$_GETTER_perfil.descricao === "PACIENTE";

    const perfilId = JSON.parse(sessionStorage.vuex).perfil.id;
    const unidadeId = JSON.parse(sessionStorage.vuex).unidade.id;
    if ((perfilId == 2 || perfilId == 1) && unidadeId == 24) {
      this.telemedicinaVisivel = true;
    }
  },
};
</script>
<style scoped>
.menu-item {
  margin: 1rem 0 1rem 0;
}

.list-unstyled {
  background-color: #fff;
}

::-webkit-scrollbar {
  width: 0px;
}

::-webkit-scrollbar-track {
  background: transparent;
}

#side-menu {
  text-align-last: left;
}

.left-side-menu {
  background-color: #3e682a;
  /*overflow-y: scroll;*/
}

#sidebar-menu .nav-second-level {
  padding-left: 0;
}

#sidebar-menu > ul > li > a,
#sidebar-menu .nav-second-level li a {
  color: #fff;
  font-weight: 700;
  text-align: left;
}

#sidebar-menu .nav-second-level li a {
  display: flex;
}

#sidebar-menu > ul > li > a:hover,
#sidebar-menu .nav-second-level li a:hover,
#sidebar-menu > ul > li > a:active,
#sidebar-menu .nav-second-level li a:active {
  color: #fff;
  opacity: 0.5;
}

#sidebar-menu .nav-second-level li a i {
  display: inline-block;
  line-height: 1.25rem;
  margin: 0 10px 0 5px;
  text-align: center;
  vertical-align: middle;
  width: 20px;
  font-size: 14px;
}
</style>
