<template>
  <div>
    <v-card>
      <v-toolbar style="background-color: #3e682a; color: #fff">
        <v-btn icon dark @click="$emit('close')">
          <v-icon>mdi-close</v-icon>
        </v-btn>
        <v-toolbar-title>
          <span class="headline">Encaminhamento</span>
        </v-toolbar-title>
        <v-spacer></v-spacer>
      </v-toolbar>
      <v-card-text>
        <v-container>
          <v-text-field v-model="medico" label="Nome do médico"></v-text-field>
          <v-select
            v-model="convenioSelecionado"
            :items="convenios"
            label="Convênio"
            class="mb-2"
          >
          </v-select>
        </v-container>
        <div class="row">
          <div class="col-md-12 text-right d-flex justify-lg-space-between">
            <button
              class="btn btn-cancelar btn-padding font-weight-bold text-gray"
              @click="$emit('close')"
            >
              Cancelar
            </button>
            <button
              class="btn btn-app-primary btn-padding font-weight-bold"
              @click="gerarPdf()"
            >
              <span> Gerar PDF </span>
            </button>
          </div>
        </div>
      </v-card-text>
    </v-card>
  </div>
</template>

<script>
export default {
  data: () => ({
    medico: "",
    convenios: ["Público", "Particular"],
    convenioSelecionado: "",
  }),
  methods: {
    gerarPdf() {
      if (this.medico == "") {
        3;
        console.log("Faltou o nome do médico");
        return;
      }
      this.$emit("gerarPDF", {
        doc: "encaminhamento",
        nomeMedico: this.medico,
        convenio: this.convenioSelecionado
          .normalize("NFD")
          .replace(/[\u0300-\u036f]/g, "")
          .toLowerCase(),
      });
      this.medico = "";
    },
  },
};
</script>
