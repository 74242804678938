<template>
  <div>
    <div class="p-lg-3">
      <div class="row justify-content-between">
        <h3 class="mt-0 mb-2 font-weight-bold text-left">
          Telemedicina Consultas
        </h3>
      </div>
    </div>
    <div class="row justify-content-center">
      <v-select
        :items="especialidadeFiltro"
        label="Selecionar Especialidade"
        item-text="descricao"
        item-value="id"
        class="col-10 justtify-center my-5"
        v-model="especialidade"
      ></v-select>
    </div>
    <v-data-table
      :footer-props="{ 'items-per-page-options': [5, 10, 15, 20] }"
      :headers="headers"
      :items="consultasEspecialidades"
      :server-items-length="totalList"
      :options.sync="options"
      class="elevation-1"
      :loading="loading"
      :loading-text="'Carregando...'"
      v-if="especialidade != ''"
    >
      <template v-slot:item.actions="{ item }">
        <button
          @click="iniciarConsulta(item)"
          class="btn btn-app-primary btn-rounded font-weight-bold m-0"
        >
          <span> Iniciar Consulta </span>
        </button>
      </template>

      <template v-slot:no-data>
        <p class="text-center">Nenhum registro encontrado</p>
      </template>
    </v-data-table>
  </div>
</template>

<script>
import EspecialidadeService from "../services/especialidade_service.js";
import FilaEspecialidadeService from "../services/fila_especialidade_service.js";
import FilaAtentederEspecialidadeService from "../services/atender_especialidade_service.js";
import AgendamentoSalaService from "../services/agendamento_sala_service.js";
export default {
  data() {
    return {
      services: {
        especialidadeService: EspecialidadeService.build(),
        filaEspecialidadeService: FilaEspecialidadeService.build(),
        filaAtentederEspecialidadeService: FilaAtentederEspecialidadeService.build(),
        agendamentoSalaService: AgendamentoSalaService.build(),
      },
      especialidade: "",
      especialidadeFiltro: [],
      consultasEspecialidades: [],
      headers: [
        {
          text: "Tutor",
          align: "start",
          sortable: false,
          value: "nome_pessoa",
        },
        {
          text: "Pet",
          align: "start",
          sortable: false,
          value: "nome_pet",
        },
        {
          text: "Espécie",
          align: "start",
          sortable: false,
          value: "nome_especie",
        },
        {
          text: "Ações",
          align: "start",
          sortable: false,
          value: "actions",
        },
      ],
      totalList: 10,
      options: {
        page: 1,
        pageCount: 0,
        itemsPerPage: 15,
        order: false,
        desc: false,
      },
      loading: false,
    };
  },
  watch: {
    especialidade() {
      this.pesquisaEspecialidade();
    },
  },
  mounted() {
    this.listarEspecialidades();
  },
  methods: {
    async pesquisaEspecialidade() {
      this.loading = true;
      this.consultasEspecialidades = [];
      await this.services.filaEspecialidadeService
        .getListByEspecialidadeId(
          this.especialidade,
          this.getPaginationParams()
        )
        .then(async (response) => {
          if (response.status !== 200) return;
          const { data } = await response.json();
          const { pagination, filas } = data;
          this.totalList = pagination.num_rows;
          this.consultasEspecialidades = filas;
        })
        .catch((e) => {
          console.error(e);
        })
        .finally(() => {
          this.loading = false;
        });
    },
    async listarEspecialidades() {
      await this.services.especialidadeService
        .getEspecialidadeList()
        .then(async (response) => {
          if (response.status !== 200) return;
          const { data } = await response.json();
          this.especialidadeFiltro = data.especialidades;
        })
        .catch((e) => {
          console.error(e);
        })
        .finally(() => {});
    },
    async iniciarConsulta(item) {
      const idMedico = ~~JSON.parse(sessionStorage.vuex).usuario.dados.medicoId;
      const idRequisicaoProcedimento = item.id_requisicao_procedimento;
      await this.services.filaAtentederEspecialidadeService
        .atenderEspecialidadeTelevet(idRequisicaoProcedimento, idMedico)
        .then(async (response) => {
          if (response.status !== 200) return;
          this.getSalaConferencia(idRequisicaoProcedimento);
          const { message } = await response.json();
          if (message) {
            this.$_ACTIONS_showSnackbarMessage({
              message: message,
              color: "sucess",
            });
          }
        })
        .catch((e) => {
          console.error(e);
        })
        .finally(() => {});
    },
    async getSalaConferencia(idRequisicaoProcedimento) {
      await this.services.agendamentoSalaService
        .getSalaAgendamento(idRequisicaoProcedimento)
        .then(async (response) => {
          if (response.status !== 200) return;
          const { data } = await response.json();
          const url = data.urlmeeting;
          window.open(url, "_blank");
          this.$router.push("principal");
        })
        .catch((e) => {
          console.error(e);
        })
        .finally(() => {});
    },
    getPaginationParams() {
      return {
        page: this.options.page,
        per_page: this.options.itemsPerPage,
      };
    },
  },
};
</script>

<style></style>
