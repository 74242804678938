<template>
  <v-dialog
    v-model="dialog"
    fullscreen
    hide-overlay
    transition="dialog-bottom-transition"
  >
    <template v-slot:activator="{ on, attrs }">
      <v-btn
        small
        depressed
        color="primary"
        class="mb-3"
        v-bind="attrs"
        v-on="on"
      >
        Consultar Cadastro
      </v-btn>
    </template>

    <v-card v-if="dialog">
      <v-toolbar style="background-color: #3e682a; color: #fff">
        <v-btn icon dark @click="dialog = false">
          <v-icon>mdi-close</v-icon>
        </v-btn>
        <v-toolbar-title>Consultar Cadastro</v-toolbar-title>
        <v-spacer></v-spacer>
        <v-toolbar-items> </v-toolbar-items>
      </v-toolbar>

      <ConsultaTutor />
    </v-card>
  </v-dialog>
</template>

<script>
import ConsultaTutor from "@/views/pacientes_teste.vue";

export default {
  components: {
    ConsultaTutor,
  },
  data() {
    return {
      dialog: false,
    };
  },
};
</script>
