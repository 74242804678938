import HTTPService from "@/services/http_service.js";
import { global } from "@/config/constants";

export default class PetsService extends HTTPService {
  resource = "pet";

  static build() {
    return new PetsService();
  }

  /**
     @returns {Promise<Response>}
     */
  getPetsPaginatedList({ page, per_page, search, order, desc, filterId }) {
    const filter = {
      page,
      per_page,
      order,
      desc,
    };
    if (filterId) {
      filter.id = search;
    } else if (~~search) {
      let cpfMask = [-1, -1, -1, ".", -1, -1, -1, ".", -1, -1, -1, "-", -1, -1];
      let reverseCpf = [...search].reverse();
      let cpf = [];
      let i;
      for (i = 0; i < cpfMask.length; i++) {
        if (cpfMask[i] === -1) {
          const digit = reverseCpf.pop();
          if (digit === undefined) {
            break;
          }
          cpfMask[i] = digit;
        }
      }
      for (let j = 0; j < i + 1; j++) {
        if (cpfMask[j] === -1) {
          break;
        }
        cpf.push(cpfMask[j]);
      }
      cpf = cpf.join("");
      filter.cpf = cpf;
    } else {
      filter.nome = search;
    }
    return this.get("pet", filter);
  }

  async getRacas() {
    return await this.get("raca").then((response) => {
      if (response.status !== 200) return [];
      const data = response.json();
      return data;
    });
  }
  async getPetById(petId) {
    return await this.get(`pet/${petId}`).then(async (response) => {
      if (response.status !== 200) return [];
      const { data } = await response.json();
      return data;
    });
  }
  async getPetByIdClient(petId) {
    return await this.get(`pet/cliente/${petId}`).then(async (response) => {
      if (response.status !== 200) return [];
      const { data } = await response.json();
      return data;
    });
  }

  getPetByTutorId(idTutor) {
    return this.get(`pet/tutor/${idTutor}`)
      .then((resp) => resp.json())
      .then((resp) => resp.data.pets);
  }

  getPetAge(dataNascimento) {
    return this.get(`pet/idade/${dataNascimento}`).then((resp) => resp.json());
  }

  async getConsultas(petId) {
    return await this.get(`consulta/pet/${petId}`);
  }

  async register(pet) {
    // this.fileRequest(responseFunctions, "POST", this.resource, pet);
    const headers = new Headers();

    const token = JSON.parse(sessionStorage.getItem("vuex")).token;
    headers.append("Authorization", "Bearer " + token);

    const requestOptions = {
      method: "POST",
      body: pet,
      headers: headers,
    };
    return await fetch(
      `${global.api.baseURL}/${this.resource}`,
      requestOptions
    ).then((response) => {
      return response;
    });
  }

  async getPetList() {
    try {
      const response = await this.get("perfil");
      if (response.status !== 200) return [];
      const { data } = await response.json();
      return data.perfis.map(({ perfil_id, perfil_nome }) => ({
        perfilId: ~~perfil_id,
        descricao: perfil_nome || "",
      }));
    } catch (e) {
      return [];
    }
  }

  getPetsByCliente() {
    return this.get(`pet`);
  }

  async editarPet(petId, pet) {
    const headers = new Headers();
    const token = JSON.parse(sessionStorage.getItem("vuex")).token;
    headers.append("Authorization", "Bearer " + token);

    const requestOptions = {
      method: "POST",
      body: pet,
      headers: headers,
    };
    return await fetch(
      `${global.api.baseURL}/${this.resource}/${petId}`,
      requestOptions
    ).then(async (response) => {
      return response;
    });
  }
}
