<template>
  <div>
    <div class="p-lg-3">
      <div class="row justify-content-between">
        <h3 class="mt-0 mb-2 font-weight-bold text-left">Minhas Consultas</h3>
      </div>
    </div>

    <v-tabs v-model="tab" background-color="transparent" color="basil" grow>
      <v-tab :key="consulta">
        Consultas
      </v-tab>
      <v-tab :key="historico">
        Histórico
      </v-tab>
    </v-tabs>

    <v-tabs-items v-model="tab">
      <v-tab-item :key="consulta">
        <div class="row justify-content-end">
          <button
            @click="getFilaCliente()"
            class="btn btn-app-primary btn-rounded font-weight-bold m-2"
          >
            <span> Atualizar </span>
          </button>
        </div>
        <v-data-table
          :footer-props="{ 'items-per-page-options': [5, 10, 15, 20] }"
          :headers="headersConsultas"
          :items="listaConsultas"
          :server-items-length="totalListConsultas"
          :options.sync="optionsConsulta"
          class="elevation-1"
          :loading="loadingConsultas"
          :loading-text="'Carregando...'"
        >
          <template v-slot:item.actions="{ item }">
            <button
              class="btn btn-app-primary btn-app-aguardo btn-rounded font-weight-bold m-0"
              disabled
              v-if="item.id_medico == null"
            >
              <span>Aguardando</span>
            </button>
            <button
              v-else
              @click="getSalaConferencia(item.id_requisicao_procedimento)"
              class="btn btn-app-primary btn-rounded font-weight-bold m-0"
            >
              <span>Entrar na sala</span>
            </button>
          </template>

          <template v-slot:no-data>
            <p class="text-center">Nenhum registro encontrado</p>
          </template>
        </v-data-table>
      </v-tab-item>
      <v-tab-item :key="historico">
        <v-data-table
          v-on:update:sort-by="options.ordenacao = true"
          :footer-props="{ 'items-per-page-options': [5, 10, 15, 20] }"
          :search="search"
          :headers="headers"
          :items="consultas"
          :options.sync="options"
          :server-items-length="totalList"
          class="elevation-1"
          :loading="loading"
          :loading-text="'Carregando...'"
        >
          <!-- <template v-slot:top> -->
          <!-- <v-toolbar flat> -->
          <!-- DIALOG DE CADASTRO -->
          <!-- <v-spacer></v-spacer>
              <v-spacer></v-spacer>
              <v-spacer></v-spacer>
              <v-spacer></v-spacer>
              <v-spacer></v-spacer>
              <v-spacer></v-spacer>
              <v-text-field
                v-model="search"
                append-icon="mdi-magnify"
                label="Pesquisar pelo nome do pet"
                single-line
                hide-details
              />
            </v-toolbar> -->
          <!-- </template> -->
          <template v-slot:item.actions="{ item }">
            <div v-if="!item.isCancelado">
              <div class="col-6 text-left">
                <button
                  v-if="item.isConfirmado"
                  class="btn btn-app-primary btn-rounded font-weight-bold"
                >
                  <span> Acessar </span>
                </button>
              </div>
              <v-row v-if="!item.isConfirmado" class="mb-2">
                <!--            <div class="col-3 text-left mx-5">-->
                <!--              <button class="btn btn-app-primary btn-rounded font-weight-bold">-->
                <!--                <span> Reagendar </span>-->
                <!--              </button>-->
                <!--            </div>-->

                <button
                  @click="showCancelarConsulta(item)"
                  class="btn btn-app-primary btn-rounded font-weight-bold"
                  v-if="item.isCancelado"
                >
                  {{ item.isCancelado }}
                  <span> Cancelar </span>
                </button>
                <v-dialog v-model="dialogCancelarConsulta" max-width="500px">
                  <v-card>
                    <div v-if="!loadingCancelar">
                      <v-card-title class="headline" style="word-break: normal"
                        >Tem certeza de que deseja desmarcar a consulta?
                      </v-card-title>
                      <v-card-actions>
                        <v-spacer></v-spacer>
                        <v-btn
                          color="#3E682A"
                          text
                          @click="closeCancelarConsulta"
                        >
                          Não
                        </v-btn>
                        <v-btn
                          class="btn-danger"
                          text
                          style="color: #fff !important"
                          @click="cancelarConsulta"
                        >
                          Sim
                        </v-btn>
                        <v-spacer></v-spacer>
                      </v-card-actions>
                    </div>
                    <v-card-actions v-else>
                      <v-spacer></v-spacer>
                      <v-spacer></v-spacer>
                      <v-row>
                        <v-progress-circular
                          class="py-5 my-5"
                          :size="70"
                          :width="5"
                          indeterminate
                          color="green"
                        ></v-progress-circular>
                      </v-row>
                      <v-spacer></v-spacer>
                    </v-card-actions>
                  </v-card>
                </v-dialog>

                <button
                  @click="getMeeting(item)"
                  class="btn btn-app-primary btn-rounded font-weight-bold m-0"
                  v-if="item.statusMeeting != null"
                >
                  <span> Acessar Meeting </span>
                </button>
              </v-row>
            </div>
          </template>
          <template v-slot:no-data>
            <p class="text-center">Nenhum registro encontrado</p>
          </template>
        </v-data-table>
      </v-tab-item>
    </v-tabs-items>
  </div>
</template>

<script>
import Mixin from "@/mixins/vuex_snackbar_mixin.js";
import VuexUsuarioMixin from "@/mixins/vuex_usuario_mixin.js";
import MinhasConsultasService from "@/services/minhas_consultas_service.js";
import FilaClienteService from "../services/fila_cliente_service.js";
import AgendamentoSalaService from "../services/agendamento_sala_service.js";

export default {
  mixins: [Mixin, VuexUsuarioMixin],
  components: {},
  data: () => ({
    tab: null,
    loadingCancelar: false,
    consultaCancelar: {},
    dialogCancelarConsulta: false,
    search: "",
    options: {
      page: 1,
      pageCount: 0,
      itemsPerPage: 15,
      order: false,
      desc: false,
    },
    totalList: 10,
    consultas: [],
    headers: [
      {
        text: "data",
        align: "start",
        sortable: true,
        value: "data",
      },
      { text: "Pet", value: "pet", sortable: false },
      { text: "Especialidade", value: "especialidade", sortable: false },
      { text: "Status", value: "status", sortable: false },
      { text: "", align: "end", value: "actions", sortable: false },
    ],
    loading: false,
    minhasConsultasService: MinhasConsultasService.build(),
    filaClienteService: FilaClienteService.build(),
    agendamentoSalaService: AgendamentoSalaService.build(),
    listaConsultas: [],
    headersConsultas: [
      {
        text: "Pet",
        align: "start",
        sortable: false,
        value: "nome_pet",
      },
      {
        text: "Raça",
        align: "start",
        sortable: false,
        value: "nome_raca",
      },
      // {
      //   text: "Data Agendamento",
      //   align: "start",
      //   sortable: false,
      //   value: "data_agendamento",
      // },
      {
        text: "Ações",
        align: "end",
        sortable: false,
        value: "actions",
      },
    ],
    totalListConsultas: 10,
    optionsConsulta: {
      page: 1,
      pageCount: 0,
      itemsPerPage: 15,
      order: false,
      desc: false,
    },
    loadingConsultas: false,
  }),

  watch: {
    search() {},
    options: {
      handler() {
        // this.options.desc = this.options.sortDesc[0] ?? false
        this.setMinhaConsulta();
      },
      deep: true,
    },
    optionsConsulta: {
      handler() {
        // this.options.desc = this.options.sortDesc[0] ?? false
        this.getFilaCliente();
      },
      deep: true,
    },
  },
  beforeCreate() {},
  mounted() {
    setTimeout(() => {
      this.setMinhaConsulta();
      this.loopFilaCliente();
      // this.getFilaCliente();
    }, 500);
  },
  computed: {},
  methods: {
    loopFilaCliente() {
      const perfilId = JSON.parse(sessionStorage.vuex).perfil.id;
      this.getFilaCliente();
      setTimeout(() => {
        if (perfilId == 4) {
          this.loopFilaCliente();
        }
      }, 180 * 1000);
    },
    async setMinhaConsulta() {
      this.loading = true;
      await this.minhasConsultasService
        .getMinhasConsultas(this.getPacienteId(), this.getPaginationParams())
        .then(async (response) => {
          if (response.status !== 200) return;
          const { data } = await response.json();
          const { pagination, consultas } = data;
          this.totalList = pagination.num_rows;
          this.consultas = consultas.map((consulta) =>
            this.mapConsulta(consulta)
          );
        })
        .finally(() => {
          this.loading = false;
        });
    },
    getPacienteId() {
      return this.$_GETTERS_usuario.id;
    },
    async getMeeting(consulta) {
      await this.minhasConsultasService
        .getUrlMeeting(consulta.agendamentoId)
        .then((response) => {
          console.log(response.status != 200);
          if (response.status != 200) {
            this.$_ACTIONS_showSnackbarMessage({
              message: "Ainda não foi possível acessar a reunião",
              color: "error",
            });
            return;
          }
          const { data } = response.json();
          window.open(data.urlmeeting);
        });
    },
    async getFilaCliente() {
      this.loadingConsultas = true;
      this.listaConsultas = [];
      await this.filaClienteService
        .getListByCliente(
          this.$_GETTERS_usuario.dados.clienteId,
          this.getPaginationConsultaParams()
        )
        .then(async (response) => {
          if (response.status !== 200) return;
          const { data } = await response.json();
          const { pagination, filas } = data;
          this.totalListConsultas = pagination.num_rows;
          this.listaConsultas = filas;
        })
        .catch((e) => {
          console.error(e);
        })
        .finally(() => {
          this.loadingConsultas = false;
        });
    },
    async getSalaConferencia(idRequisicaoProcedimento) {
      await this.agendamentoSalaService
        .getSalaAgendamento(idRequisicaoProcedimento)
        .then(async (response) => {
          if (response.status !== 200) return;
          const { data } = await response.json();
          const url = data.urlmeeting;
          window.open(url, "_blank");
        })
        .catch((e) => {
          console.error(e);
        })
        .finally(() => {});
    },
    mapConsulta(consulta) {
      return {
        data: new Date(
          consulta.data + " " + consulta.hora_inicio
        ).toLocaleString("pt-BR"),
        pet: consulta.nome_pet,
        especialidade: consulta.procedimento,
        isConfirmado: false,
        status: consulta.status ?? "Agendado",
        isCancelado: false, // consulta.status === "cancelado",
        agendamentoId: consulta.id_agendamento,
        unidade: consulta.unidade_descricao ?? "Não informado",
        statusMeeting: consulta.status_meeting,
      };
    },
    getPaginationParams() {
      return {
        page: this.options.page,
        per_page: this.options.itemsPerPage,
        sort: this.options.order,
        desc: this.options.desc,
        search: this.search,
      };
    },
    getPaginationConsultaParams() {
      return {
        page: this.optionsConsulta.page,
        per_page: this.optionsConsulta.itemsPerPage,
        sort: this.optionsConsulta.order,
        desc: this.optionsConsulta.desc,
      };
    },
    showCancelarConsulta(consulta) {
      this.dialogCancelarConsulta = true;
      this.consultaCancelar = consulta;
    },
    closeCancelarConsulta() {
      this.dialogCancelarConsulta = false;
    },
    async cancelarConsulta() {
      this.loadingCancelar = true;
      await this.minhasConsultasService
        .cancelarConsulta(this.consultaCancelar.agendamentoId)
        .then(async (response) => {
          if (response.status !== 200) this.showMensagemErro();
          const { data } = await response.json();
          this.$_ACTIONS_showSnackbarMessage({
            text: data.message,
            color: "success",
          });
          this.setMinhaConsulta();
        })
        .finally(() => {
          this.loadingCancelar = false;
          this.closeCancelarConsulta();
        });
    },
    showMensagemErro() {
      this.$_ACTIONS_showSnackbarMessage({
        message: "Erro ao desmarcar consulta.",
        color: "error",
      });
    },
  },
};
</script>
<style scoped>
.btn-app-aguardo {
  background-color: #f0a211 !important;
  color: black !important;
}
</style>
