<template>
  <div class="info-container d-flex justify-content-between w-50 ml-5">
    <div class="text-left ml-10">
      <v-card-text>
        <div class="mb-3">
          <div class="mb-3" v-if="perfil == 2">
            <v-dialog v-model="dialogRequisitarExame" persistent fullscreen>
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                  v-bind="attrs"
                  v-on="on"
                  small
                  title="REQUISITAR EXAMES"
                  class="actions mt-2"
                  text
                >
                  <v-icon small>mdi-test-tube</v-icon>
                </v-btn>
              </template>
              <RequisitarExamesPets
                v-if="dialogRequisitarExame"
                @close="() => (dialogRequisitarExame = false)"
                :petId="infoPaciente.PetCodigo"
                :tutorId="infoPaciente.TutorCodigo"
              />
            </v-dialog>

            <v-dialog
              persistent
              fullscreen
              v-model="dialogRequisitarInternacao"
            >
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                  v-bind="attrs"
                  v-on="on"
                  small
                  title="REQUISITAR INTERNAÇÃO"
                  class="actions mt-2"
                  text
                >
                  <v-icon small>mdi-hospital-building</v-icon>
                </v-btn>
              </template>
              <requisitar-internacao
                v-if="dialogRequisitarInternacao"
                :petId="infoPaciente.PetCodigo"
                :consultaId="0"
                @close="dialogRequisitarInternacao = false"
              ></requisitar-internacao>
            </v-dialog>

            <v-dialog persistent fullscreen v-model="dialogRequisitarMedicacao">
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                  v-bind="attrs"
                  v-on="on"
                  small
                  title="REQUISITAR MEDICAÇÃO"
                  class="actions mt-2"
                  text
                >
                  <v-icon small>mdi-pill</v-icon>
                </v-btn>
              </template>
              <requisitar-medicacao
                v-if="dialogRequisitarMedicacao"
                :petId="infoPaciente.PetCodigo"
                :consultaId="0"
                @close="dialogRequisitarMedicacao = false"
              ></requisitar-medicacao>
            </v-dialog>

            <v-dialog persistent fullscreen v-model="dialogRequisitarCirurgia">
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                  v-bind="attrs"
                  v-on="on"
                  small
                  title="REQUISITAR CIRURGIA"
                  class="actions mt-2"
                  text
                >
                  <v-icon small>mdi-box-cutter</v-icon>
                </v-btn>
              </template>
              <requisitar-cirurgia
                v-if="dialogRequisitarCirurgia"
                :petId="infoPaciente.PetCodigo"
                :consultaId="0"
                @close="dialogRequisitarCirurgia = false"
              ></requisitar-cirurgia>
            </v-dialog>

            <v-dialog persistent fullscreen v-model="dialogRequisitarConsulta">
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                  v-bind="attrs"
                  v-on="on"
                  small
                  title="REQUISITAR CONSULTA"
                  class="actions mt-2"
                  text
                >
                  <v-icon small>mdi-stethoscope</v-icon>
                </v-btn>
              </template>
              <requisitar-consulta
                v-if="dialogRequisitarConsulta"
                :petId="infoPaciente.PetCodigo"
                :tutorId="infoPaciente.TutorCodigo"
                @close="dialogRequisitarConsulta = false"
              ></requisitar-consulta>
            </v-dialog>
          </div>
          <template v-if="dialogComandas">
            <v-dialog
              v-model="dialogComandas"
              fullscreen
              hide-overlay
              transition="dialog-bottom-transition"
            >
              <v-card>
                <v-toolbar dark style="background-color: #3e682a; color: #fff">
                  <v-btn icon dark @click="dialogComandas = false">
                    <v-icon>mdi-close</v-icon>
                  </v-btn>
                  <v-toolbar-title>Comandas </v-toolbar-title>
                  <v-spacer></v-spacer>
                  <v-toolbar-items> </v-toolbar-items>
                </v-toolbar>

                <modal-comandas :tutor="tutor" :petId="petIdComanda" />
              </v-card>
            </v-dialog>
          </template>
          <v-btn
            small
            depressed
            color="success"
            @click="listProntuarios"
            class="mr-2"
          >
            Prontuários
          </v-btn>
          <v-btn
            small
            depressed
            color="warning"
            @click="handleDialogComandas"
            class="mr-2"
          >
            Comandas
          </v-btn>
          <v-btn
            small
            depressed
            color="primary"
            @click="dialogSelectRequisicao = true"
            v-if="perfil == 3"
          >
            Requisições
          </v-btn>
        </div>
        <span>
          <strong>Tutor: </strong>
          {{ infoPaciente.NomeTutor || infoPaciente.UsuarioSenha }} </span
        ><br />
        <span>
          <strong>Pet: </strong>
          {{ infoPaciente.PetCodigo + " - " + infoPaciente.NomePet }} </span
        ><br />
        <!-- <v-dialog v-model="dialogObservacoes" width="500">
          <template v-slot:activator="{ on, attrs }">
            <v-btn
              small
              depressed
              color="error"
              v-bind="attrs"
              v-on="on"
              class="mt-3"
              >Observações
            </v-btn>
          </template>
          <v-card>
            <v-card-title class="text-h5 bg-danger text-white lighten-2">
              Observações
            </v-card-title>

            <h5 class="text-justify pt-5 px-5">
              {{ infoPaciente.ObservacaoExtra }}
            </h5>

            <v-divider></v-divider>

            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn color="primary" text @click="dialogObservacoes = false">
                Ok
              </v-btn>
            </v-card-actions>
          </v-card> </v-dialog
        ><br /> -->
        <!-- <span>
          <strong>Observações: </strong>
          {{ infoPaciente.Observacao }} </span
        > -->
        <span class="text-justify font-weight-bold">
          Observações:
          <b class="red--text font-weight-regular">{{
            infoPaciente.ObservacaoExtra
          }}</b> </span
        ><br />

        <!-- <v-dialog
          v-model="dialogSelectProntuarios"
          v-if="dialogSelectProntuarios"
          max-width="500px"
        >
          <v-card>
            <v-card-title>Visualizar Prontuarios</v-card-title>
            <v-card-text>
              <v-autocomplete
                v-model="selectedTutorProntuarios"
                :loading="loadingSearch"
                :items="tutores"
                :search-input.sync="searchTutorProntuarios"
                hide-no-data
                hide-selected
                label="Selecione um tutor"
                item-text="nome"
                return-object
              ></v-autocomplete>
            </v-card-text>

            <v-card-text>
              <v-select
                :items="pets"
                v-model="selectedPetProntuarios"
                label="Selecione um pet"
                item-text="nome"
                return-object
              ></v-select>
            </v-card-text>

            <v-card-actions>
              <v-btn color="gray" text @click="dialogSelectProntuarios = false">
                Fechar
              </v-btn>
              <v-btn
                small
                depressed
                :disabled="!selectedPetProntuarios"
                color="primary"
                @click="listProntuarios"
              >
                Buscar
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog> -->
        <v-dialog
          v-model="dialogProntuarios"
          v-if="dialogProntuarios"
          fullscreen
          persistent
        >
          <prontuarios
            @close="() => (dialogProntuarios = false)"
            :consultas="prontuarios"
          />
        </v-dialog>
        <v-dialog v-model="dialogErrorProtuarios" width="400">
          <v-card>
            <v-card-title class="text-h5 bg-danger text-white lighten-2">
              Error
            </v-card-title>

            <h4 class="text-center pt-5 px-5">
              Dados não encontrados
            </h4>

            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn
                color="primary"
                text
                @click="dialogErrorProtuarios = false"
              >
                Ok
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>

        <v-dialog
          v-model="dialogSelectRequisicao"
          v-if="dialogSelectRequisicao"
          max-width="500px"
        >
          <v-card>
            <v-card-title class="primary white--text"
              >Requisitar Serviço</v-card-title
            >
            <v-card-text class="my-4">
              <v-select
                :items="requisicoes"
                v-model="selectedRequisicao"
                label="Selecione o que deseja requisitar"
              ></v-select>
            </v-card-text>
            <v-card-actions class="d-flex border justify-end">
              <v-btn color="gray" text @click="dialogSelectRequisicao = false">
                Fechar
              </v-btn>
              <v-btn
                small
                depressed
                :disabled="!selectedRequisicao"
                color="primary"
                @click="requisitar"
              >
                Requisitar
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>
        <v-dialog v-model="dialogRequisitarExame" fullscreen persistent>
          <RequisitarExamesPets
            v-if="dialogRequisitarExame"
            @close="() => (dialogRequisitarExame = false)"
            :petId="infoPaciente.PetCodigo"
            :tutorId="infoPaciente.TutorCodigo"
          />
        </v-dialog>
        <v-dialog v-model="dialogRequisitarMedicacao" fullscreen persistent>
          <RequisitarMedicacao
            v-if="dialogRequisitarMedicacao"
            @close="() => (dialogRequisitarMedicacao = false)"
            :petId="infoPaciente.PetCodigo"
            :tutorId="infoPaciente.TutorCodigo"
          />
        </v-dialog>
      </v-card-text>
    </div>
  </div>
</template>

<script>
import Prontuarios from "@/components/prontuarios/prontuarios.vue";
import PetsService from "@/services/pets_service.js";
import PacienteService from "@/services/paciente_service.js";
import Mixin from "@/mixins/vuex_mixin.js";
import RequisitarExamesPets from "@/components/requisitar_exames/requisitar_exame.vue";
import RequisitarMedicacao from "@/components/recepcao/infoChamada/buttons/requisitar-medicacao-pet.vue";
import RequisitarConsulta from "@/components/recepcao/infoChamada/buttons/marcar-consulta/marcarConsulta.vue";
import RequisitarInternacao from "@/components/requisitar_internacao";
import RequisitarCirurgia from "@/components/requisitar_cirurgia";
import ModalComandas from "@/components/pacientes/modal_comandas_teste.vue";

export default {
  mixins: [Mixin],
  components: {
    Prontuarios,
    RequisitarExamesPets,
    RequisitarMedicacao,
    RequisitarConsulta,
    RequisitarInternacao,
    RequisitarCirurgia,
    ModalComandas,
  },
  data() {
    return {
      dialogErrorProtuarios: false,
      dialogObservacoes: false,
      infoPaciente: {},
      loadingSearch: false,
      tutores: [],
      pets: [],
      prontuarios: [],
      dialogSelectProntuarios: false,
      dialogProntuarios: false,
      selectedTutorProntuarios: "",
      selectedPetProntuarios: "",
      searchTutorProntuarios: "",
      petService: PetsService.build(),
      requisicoes: ["Exame", "Medicação"],
      dialogSelectRequisicao: false,
      dialogRequisitarExame: false,
      dialogRequisitarMedicacao: false,
      dialogComandas: false,
      selectedRequisicao: "",
      perfil: "",
      dialogRequisitarCirurgia: false,
      dialogRequisitarConsulta: false,
      dialogRequisitarInternacao: false,
      tutor: {},
      petIdComanda: null,
    };
  },
  watch: {
    searchTutorProntuarios(nome) {
      if (!nome) {
        return;
      }
      if (
        !nome ||
        !(nome = nome.trim()) ||
        nome.length < 3 ||
        this.loadingSearch
      ) {
        return;
      }
      this.loadingSearch = true;
      this.tutores = [];
      const pacienteService = new PacienteService();
      pacienteService.getPacienteByName(
        {
          onSucess: (status) => (body) => {
            if (status === 200) {
              this.tutores = body.data.pacientes;
            } else {
              this.tutores = [];
            }
          },
          onError: (error) => {
            console.error(error);
            this.tutores = [];
          },
          onEnd: () => {
            this.loadingSearch = false;
          },
        },
        nome
      );
    },
    selectedTutorProntuarios() {
      this.pets = "";
      this.pets = this.selectedTutorProntuarios.pets;
    },
  },
  beforeMount() {
    this.infoPaciente = JSON.parse(localStorage.getItem("infoPaciente"));
    console.log(this.infoPaciente);
    this.perfil = JSON.parse(sessionStorage.vuex).perfil.id;
  },
  methods: {
    async handleDialogComandas() {
      if (this.infoPaciente.TutorCodigo) {
        this.petIdComanda = this.infoPaciente.PetCodigo;
        const pacienteService = new PacienteService();
        this.tutor = await pacienteService
          .getPacienteByClienteId(this.infoPaciente.TutorCodigo)
          .then((resp) => resp.data);
        this.dialogComandas = true;
      } else {
        this.$_ACTIONS_showSnackbarMessage({
          message: "Pet não identificado.",
          color: "error",
        });
        return;
      }
    },
    async listProntuarios() {
      await this.petService
        .getConsultas(this.infoPaciente.PetCodigo)
        .then(async (response) => {
          const data = await response.json();
          if (response.status !== 200) {
            // this.$_ACTIONS_showSnackbarMessage({
            //   message: data.message,
            //   color: "error",
            // });
            this.dialogErrorProtuarios = true;
            return;
          }
          console.log(data);
          this.prontuarios = data.data.consultas;
          this.dialogProntuarios = true;
          this.dialogSelectProntuarios = false;
        });
    },
    requisitar() {
      this.dialogSelectRequisicao = false;

      if (this.selectedRequisicao == "Exame") {
        this.dialogRequisitarExame = true;
      } else if (this.selectedRequisicao == "Medicação") {
        this.dialogRequisitarMedicacao = true;
      }
    },
    // dialogSelectPront() {
    //   this.selectedPetProntuarios = "";
    //   this.selectedTutorProntuarios = "";
    //   this.searchTutorProntuarios = "";
    //   this.dialogSelectProntuarios = true;
    // },
  },
};
</script>

<style scoped>
@media (max-width: 1080px) {
  .info-container {
    width: 80vw !important;
    margin: 0 !important;
  }
}
</style>
