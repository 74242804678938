import HTTPService from "@/services/http_service.js";
// import { global } from "@/config/constants";

export default class RequisicaoExameService extends HTTPService {
  resource = "requisicao";

  static build() {
    return new RequisicaoExameService();
  }

  async getRequisicoesAbertas(petId, unidadeId) {
    return await this.get(`${this.resource}/pet/exames/${petId}/${unidadeId}`);
  }

  async getRequisicoesInternacoesAbertas(petId, unidadeId) {
    return await this.get(
      `${this.resource}/pet/internacao/${petId}/${unidadeId}`
    );
  }

  async getRequisicoesCirurgiasAbertas(petId, unidadeId) {
    return await this.get(
      `${this.resource}/pet/cirurgia/${petId}/${unidadeId}`
    );
  }

  async getRequisicoesMedicacoesAbertas(petId, unidadeId) {
    return await this.get(
      `${this.resource}/pet/medicacao/${petId}/${unidadeId}`
    );
  }

  async getRequisicoesConsultas(petId) {
    return await this.get(`${this.resource}/pet/consulta/${petId}`);
  }

  getRequisicoesConsultasByUnidade(unidadeId) {
    return this.get(`${this.resource}/consulta/aberta/${unidadeId}`);
  }

  getRequisicoesExamesByUnidade(unidadeId) {
    return this.get(`${this.resource}/exames/aberta/${unidadeId}`);
  }

  cadastrarRequisicaoExame(requisicao) {
    return this.post(this.resource, { ...requisicao });
  }

  requisitarExamePet(requisicao) {
    return this.post(`${this.resource}/recepcao/exame`, { ...requisicao });
  }

  requisitarConsulta(idTutor, idPet, idUnidade, requisicao) {
    return this.post(`requisicao/consulta/${idTutor}/${idPet}/${idUnidade}`, {
      ...requisicao,
    });
  }

  excluirRequisicaoConsulta(requisicaoId) {
    return this.post(`${this.resource}/consulta/cancelar/${requisicaoId}`);
  }

  marcarExecutado(requisicaoId) {
    requisicaoId = ~~requisicaoId;
    return this.post(`requisicao/marcar-executado/${requisicaoId}`);
  }

  excluirRequisicao(requisicaoId) {
    return this.post(`${this.resource}/exame/cancelar-medico/${requisicaoId}`);
  }
}
